import { PublishableStatus, type Publishable } from "~/layers/admin/models";
import BaseStatus, {
  type StatusProps,
} from "@/components/pages/order/status/BaseStatus";

export default defineComponent({
  name: "PublishableStatus",
  extends: BaseStatus,
  props: {
    item: { type: Object as PropType<Publishable>, required: true },
  },
  methods: {
    getStatus(): StatusProps {
      let text = enumTitle(this.item.status);

      const defu = {
        [PublishableStatus.ACTIVE]: {
          status: "success",
          text,
        },
        [PublishableStatus.INACTIVE]: {
          color: "red lighten-4",
          iconColor: "red",
          text,
        },
        [PublishableStatus.DRAFT]: {
          empty: true,
          color: "grey darken-4",
          text,
        },
        [PublishableStatus.ARCHIVED]: {
          status: "warning",
          text,
        },
        default: {
          color: "grey lighten-2",
          text,
        },
      };

      if (this.item.hasUnpublishedChanges) {
        text = "Active (unpublished changes)";
        return match(this.item.status, {
          ...defu,
          [PublishableStatus.ACTIVE]: {
            status: "success",
            // color: "orange",
            partial: true,
            text,
          },
          default: {
            status: "warning",
            color: "orange lighten-4",
            text,
          },
        });
      }

      return match(this.item.status, {
        ...defu,
        [PublishableStatus.ACTIVE]: {
          status: "success",
          text,
        },
      });
    },
  },
});
